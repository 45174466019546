import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const PowerSemiConductorsBanner = () => {
  return (
    <Banner
      name="Products"
      description="Flexible & Cost Competitive<br/>Manufacturing Solution Services"
    >
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-power-semiconductors.jpg"
          alt="banner"
          layout="fullWidth"
          quality="100"
        />
      </ScaledImage>
    </Banner>
  )
}

export default PowerSemiConductorsBanner
