import React from 'react'
import { Row, Col } from '../UI/Grid'
import ProductCard from '../Cards/ProductCard'

const ProductList = ({ products }) => {
  return (
    <Row rowGap={{ _: 0, md: 10 }} justifyContent={products?.length < 3 ? 'center' : 'flex-start'}>
      {products.map(p => (
        <Col col={{ sm: 6, lg: 4 }} key={p.id}>
          <ProductCard
            slug={`/products/${p.slug}`}
            name={p.name}
            img={p.photo_url}
            subCat={p.product_subcategory}
          />
        </Col>
      ))}
    </Row>
  )
}

export default ProductList
