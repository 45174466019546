import React from 'react'
import { Card, CardContent } from '../UI/Card'
import { Row, Col } from '../UI/Grid'
import ArrowRightIcon from '../Icons/ArrowRightIcon'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ScaledImage from '../UI/ScaledImage'
import { Paragraph } from '../UI/Typography'
import theme from '../../utils/theme'
import Element from '../UI/Element'

const ProductCard = ({ name, slug, img, subCat }) => {
  return (
    <Product as={Link} to={slug}>
      <ProductImgWrapper>
        <ScaledImage scaleHeight={195} scaleWidth={345}>
          {img && <img src={img} alt="name" />}
        </ScaledImage>
      </ProductImgWrapper>
      <CardContent p={4}>
        <Row alignItems="center">
          <Col col="fill">
            <Paragraph color="text" fontWeight="bold" size={'h6'}>
              {name}
              {subCat === 4 && (
                <>
                  <br />
                  Co-Axial, Drop In, Surface Amount
                </>
              )}
            </Paragraph>
          </Col>
          <Col col="auto">
            <Element size="h4">
              <ArrowRightIcon />
            </Element>
          </Col>
        </Row>
      </CardContent>
    </Product>
  )
}

const ProductImgWrapper = styled.div`
  position: relative;
`

const Product = styled(Card)`
  height: 100%;
  box-shadow: none;
  border: 1px solid #bdbdbd;
  overflow: hidden;
  color: ${theme.colors.text};

  ${ProductImgWrapper} {
    transition: ${theme.transition};
  }

  &:hover {
    ${ProductImgWrapper} {
      transform: scale(1.1);
    }
    color: ${theme.colors.brand};

    border-color: #000;
  }
`

export default ProductCard
