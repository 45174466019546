import React from 'react'
import { Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Heading } from '../UI/Typography'
import { useProductsData } from '../../hooks/use-products-data'
import ProductList from './ProductList'
import DefaultVector from '../Shared/DefaultVector'
import Element from '../UI/Element'
import Button from '../UI/Button'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ArrowRightIcon from '../Icons/ArrowRightIcon'
import DownloadIcon from '../Icons/DownloadIcon'

const PowerSemiConductorsList = ({ data }) => {
  const pdf = useStaticQuery(graphql`
    query powerSemiPdfQuery {
      allFile(filter: { relativePath: { eq: "Fastech_Flyer.pdf" } }) {
        nodes {
          publicURL
        }
      }
    }
  `)
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container pb={{ lg: 20 }}>
          <Heading textAlign="center" size="h2" mb={10}>
            Power Semiconductors
          </Heading>

          <ProductList products={data} />

          <Heading size="h6" textAlign="center" mb={6} mt={10}>
            If you want to see the summary of Power Semiconductor, click the download button:
          </Heading>
          <Element textAlign="center" mb={10}>
            <Button
              variant="brand-outline"
              as="a"
              href={pdf.allFile.nodes[0].publicURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Element mr={2}>Download</Element>
              <DownloadIcon />
            </Button>
          </Element>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default PowerSemiConductorsList
